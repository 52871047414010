import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { configMainMenu } from './config/top-menu.config';
import { SalesAppService } from './services/sales-app.service';
import { AdminUserService } from './admin/user/user.service';
import { ChatHelpDeskComponent } from '../../../evolutics-shared-lib/src/lib/Shared/components/page-icons/chat-help-desk/chat-help-desk.component';
import { LoaderComponent } from 'ets-fe-ng-sdk';
import { MatProgressBar } from '@angular/material/progress-bar';
import { SharedAppComponent } from 'projects/evolutics-shared-lib/src/lib/shared-app.component';

//import { configForms } from 'ets-fe-ng-sdk';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [MatProgressBar, LoaderComponent, RouterOutlet, ChatHelpDeskComponent]
})
export class AppComponent extends SharedAppComponent {
  constructor(private sappS: SalesAppService,adminUserService:AdminUserService) {
    super();
    this.searchS.buildIndex();
    environment.versionNo = 'v1.0.0';
    environment.isSalesPortal = true;
    environment.webUserSignal.set(sappS.webUser);
    this.appS.topMenu = configMainMenu;
  }
  async ngOnInit() {
    super.ngOnInit();
    if (this.authService.isLoggedin) {
      this.authService.getAllFromLocal(true);
      // this.authService.getAllFromOnline();
    }

  }
  ngAfterViewInit() {
    return super.ngAfterViewInit();
  }
}
