import { ChangeDetectionStrategy, Component, computed, HostListener, OnInit, signal } from '@angular/core';
import { AppService } from '@Services/app.service';
import { ChatHelpDeskService } from './chat-help-desk.service';
import {
  faDatabase,
  faEye,
  faUser,
  faRocket,
  faQuestionCircle,
  faList,
  faPlus,
  faCommentDots,
  faCross,
  faWindowClose,
  faPaperPlane,
  faClipboard,
  faStickyNote,
  faBell,
  faArrowLeft,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { UtilityService } from '@Services/utility.service';
import { FormGroup, FormControl, FormsModule } from '@angular/forms';
import { UserService } from '@Services/user.service';
import { UsersService } from '@User/adminuser/user-extras/users.service';
import { NotificationsService } from '../../notifications/notifications.service';
import { FunctionCaller } from 'ets-fe-ng-sdk';
import { InputFormatDirective } from '../../../directives/input-formatter.directive';
import { AutocompleteComponent } from 'ets-fe-ng-sdk';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { NgClass, AsyncPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MessageService } from '../../../../Reusables/reusable-pages/messages/message.service';
import { MatBadgeModule } from '@angular/material/badge';

@Component({
  selector: 'app-chat-help-desk',
  templateUrl: './chat-help-desk.component.html',
  styleUrls: ['./chat-help-desk.component.scss'],
  imports: [
    CdkDrag,
    NgClass,
    MatBadgeModule,
    FontAwesomeModule,
    AutocompleteComponent,
    FormsModule,
    RouterModule,
    InputFormatDirective,
    AsyncPipe,
    FunctionCaller
],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatHelpDeskComponent implements OnInit {
  faCommentDots: IconDefinition = faCommentDots;
  faCross: IconDefinition = faWindowClose;
  faSend: IconDefinition = faPaperPlane;
  faClipboardMedical: IconDefinition = faClipboard;
  faNote: IconDefinition = faStickyNote;
  faBell: IconDefinition = faBell;
  faUser: IconDefinition = faUser;
  faList = faList;
  groupListing = true;
  userListing = true;
  showMsgValue: boolean = true;
  faArrowLeft: IconDefinition = faArrowLeft;
  faCircle: IconDefinition = faCircle;
  circleFill = '#514ef5';
  form = new FormGroup({
    user: new FormControl(null),
    group: new FormControl(null),
  });
  readonly totalPendingCounts = computed<number>(
    () => this.messageService.unreadNotificationsCount() || null,
  );
currentView=signal(View.chat) 


  constructor(
    public chS: ChatHelpDeskService,
    public appS: AppService,
    public uS: UtilityService,
    public userSer: UserService,
    public messageService: MessageService,
    public nS: NotificationsService,
    public usersSer: UsersService,
  ) {}

  ngOnInit(): void {}

  /*
   * New Chat
   * */
  faQuestionCircle: any = faQuestionCircle;

  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    this.groupListing = true;
    this.userListing = true;
  }

  toggleGroupListing(e: any) {
    e.stopPropagation();
    this.groupListing = false;
  }

  toggleUserListing(e: any) {
    e.stopPropagation();
    this.userListing = false;
  }

  close(el: HTMLDivElement) {
    el.hidden = true;
  }

  showMsg() {
    this.showMsgValue = false;
  }

  backMsg() {
    this.showMsgValue = true;
  }
}
enum View {
  chat = 'CHAT',
  help = 'HELP',
}
