import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { SUILayoutModule } from './app/layout/layout.module';
import { SalesSharedModule } from './app/shared/sales-shared.module';
import { bootstrapApplication } from '@angular/platform-browser';
import { routes } from './app/app-routing.module';
import { sharedAppConfig } from 'projects/evolutics-shared-lib/src/lib/shared-app.config';
import { EWebAccessType } from '@Shared/enums/accessType.enum';

if (environment.production) enableProdMode();
environment.isWebAccess.set(true);

function setVariables() {
  environment.sentryKey =
    'https://788180a42fae2ec8c97516faa22254d2@o4507594374250496.ingest.de.sentry.io/4507596109578320';
  environment.accessType = EWebAccessType.sales;
  return environment;
}
bootstrapApplication(
  AppComponent,
  sharedAppConfig(routes, setVariables(), [
    importProvidersFrom(SUILayoutModule, SalesSharedModule, SUILayoutModule),
  ]),
).catch((err) => console.error(err));
